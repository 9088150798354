import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/services/authService/auth.service';
import { LoginFormDto } from 'src/app/shared/dto/loginForm.dto';
import { ApplicationInfoDTO } from 'src/app/components/user/dto/AdministratorDTO';
import { environment } from 'src/environments/environment';


type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  showLoader: boolean = false;
  openNav: boolean = false
  isChecked: boolean = false;

  public loginForm: FormGroup;
  public formErrors: FormErrors = {
    'email': '',
    'password': '',
  };
  public errorMessage: any;
  appInfo:ApplicationInfoDTO=new ApplicationInfoDTO()
  constructor(public authService: AuthService,
    private fb: FormBuilder,
    private router: Router, public toster: ToastrService,
    private cookieService: CookieService, private translate: TranslateService) {
    this.loginForm = fb.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required]
    });
  }
  baseUrl=environment.baseURL
  logoImage='auth/logoImage'

  ngOnInit() {
    
    
    this.authService.getApplicationInfo().subscribe((resp:any)=>{
      if(resp)
      this.appInfo=resp.data
      console.log("appinfo2 "+(JSON.stringify(this.appInfo)))
    },(error:any)=>{
      console.log("error "+error.error.message)
    })
    if(localStorage.getItem('lang')){
      this.translate.setDefaultLang(localStorage.getItem('lang'));
    }
    else{
      localStorage.setItem('lang', 'en');
      this.translate.setDefaultLang(localStorage.getItem('lang'));
    }
  }

  // // Login With Google
  // loginGoogle() {
  //   this.authService.GoogleAuth();
  // }

  // // Login With Twitter
  // loginTwitter(): void {
  //   this.authService.signInTwitter();
  // }

  // // Login With Facebook
  // loginFacebook() {
  //   this.authService.signInFacebok();
  // }

  // Simple Login
  login() {
    this.showLoader = true;

        let loginForm = new LoginFormDto();
        loginForm.login = this.loginForm.value['email'];
        loginForm.password = this.loginForm.value['password'];
        this.authService.login(loginForm).subscribe(
          (res)=>{
            this.authService.addTokenToLocalStorage(res.data.token);
            
            this.authService.addUserToLocalStorage(res.data);
            if(this.isChecked){
              this.cookieService.set('language', this.translate.defaultLang, 7);
              this.cookieService.set('user', localStorage.getItem('userData'),30);
            }
            
            this.toster.info('Welcome ' + res.data.userNames, 'Successful Authentification');
            this.router.navigate(['/dashboard']);
          },
          (error)=>{
            if(error.status==0){
              this.toster.error('Fail connection to the remote server');
            }
            this.toster.error(error.error.message);
            this.showLoader = false;
          }
        );
  }


  onChange(isChecked: boolean){
      this.isChecked = isChecked;
  }

  changeLanguage(lang) {
      localStorage.setItem("lang", lang);
      this.translate.use(lang);
      if(this.cookieService.check('language')){
        this.cookieService.set('language', 'lang');
      }
    
  }

}
