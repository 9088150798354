import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../authService/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from './loader.service';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

constructor(private authService:AuthService,private router:Router,private toastr: ToastrService, public loaderService: LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.isLoading.next(true);

    const token=this.authService.getTokenToLocalStorage();

    if (token) {
      // If we have a token, we set it to the header
      req = req.clone({
         setHeaders: {Authorization: `Bearer ${token}`}
      });
   }


   return next.handle(req).pipe(
  	catchError((err) => {
      if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.toastr.error("You are not authorized to access to this ressource");
            localStorage.clear();
            this.router.navigate(['error/401']);
        }

          if (err.status === 0) {
              this.toastr.error("Fail to connect to the remote server");
            }
 	    }
  	  return throwError(err);
	  }),
    finalize(
      () => {
        this.loaderService.isLoading.next(false);
      }
    )
   )
    throw new Error('Method not implemented.');
  }



}
