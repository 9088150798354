import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd, RouterStateSnapshot, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserAmountInfoDTO } from 'src/app/components/billing/dto/UserBillDTO';
import { AuthorisationDto } from 'src/app/components/user/dto/Authorisation.dto';
import { UserDto } from 'src/app/components/user/dto/User.dto';
import { UserResponseDto } from 'src/app/components/user/dto/UserResponse.DTO';
import { UserAdminService } from 'src/app/components/user/services/administrator/user-admin.service';
import { AuthService } from '../../services/authService/auth.service';
import { NavService, Menu } from '../../services/nav.service';
import { TarrifModes } from 'src/app/pages/authentication/register/dto/TarrifModes';
import { environment } from 'src/environments/environment';
import { ApplicationInfoDTO } from 'src/app/components/user/dto/AdministratorDTO';

export let browserRefresh;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {

  public menuItems: Menu[];
  private authMenu: Menu[] = [];
  public url: any = 'assets/images/avtar/user.png';
  public fileurl: any;
  public user: string;
 
  authorizations: AuthorisationDto[] = [];
  subscription: Subscription;
  isSaasUser : boolean = false;
  userData: UserAmountInfoDTO;
  userOtherInfo: UserDto;
  balanceAvailable: number=0;
  lang!:string
  baseUrl=environment.baseURL
  logoImage='auth/logoImage'
  appInfo:ApplicationInfoDTO=new ApplicationInfoDTO()

  constructor(private router: Router, public navServices: NavService,
     public authService: AuthService,
     private userService: UserAdminService) {
     this.lang= localStorage.getItem("lang");
    if(this.navServices.lang=='en' || this.lang=='en'){
      this.navServices.items.subscribe(menuItems => {
        this.menuItems = menuItems
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            menuItems.filter(items => {
                  if (items.path === event.url)
                    this.setNavActive(items)
                  if (items.childrenPath.includes(event.url))
                    this.setNavActive(items)
                  if (!items.children) return false
                    items.children.filter(subItems => {
                    if (subItems.path === event.url)
                      this.setNavActive(subItems)
                    if (!subItems.children) return false
                    subItems.children.filter(subSubItems => {
                      if (subSubItems.path === event.url)
                        this.setNavActive(subSubItems)
                    })
                  })
            })
          }
        })
      });
    }
else{
  this.navServices.items2.subscribe(menuItems => {
    this.menuItems = menuItems
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        menuItems.filter(items => {
              if (items.path === event.url)
                this.setNavActive(items)
              if (items.childrenPath.includes(event.url))
                this.setNavActive(items)
              if (!items.children) return false
                items.children.filter(subItems => {
                if (subItems.path === event.url)
                  this.setNavActive(subItems)
                if (!subItems.children) return false
                subItems.children.filter(subSubItems => {
                  if (subSubItems.path === event.url)
                    this.setNavActive(subSubItems)
                })
              })
        })
      }
    })
  });
}
  
  }

  ngOnInit(): void {
    this.authService.getApplicationInfo().subscribe((resp:any)=>{
      if(resp)
      this.appInfo=resp.data
      // console.log("appinfo2 "+(JSON.stringify(this.appInfo)))
    },(error:any)=>{
      console.log("error "+error.error.message)
    })
    this.initAuthMenu();
  }

  initAuthMenu(){
    let menu = this.menuItems;
    this.menuItems = [];
    menu.forEach((item)=>{
      if(this.checkIfUserAuthorized(item)){
        this.menuItems.push(item);
        this.menuItems = this.menuItems.sort((menu, lastmenu)=>menu.id - lastmenu.id);
      }
    });
    
  }


  public checkIfUserAuthorized(item: Menu): boolean{
    let curentUser: UserDto = JSON.parse(localStorage.getItem('userData'));
    this.user = curentUser.userNames;
    
    let isAdmin=curentUser.isAdmin
    console.log("saas error "+isAdmin);
    if(!isAdmin){
      
      this.authService.getSaasUserGeneralAmountInfo(curentUser.userCode).subscribe(
        (res)=>{
          
          this.userData = res.data;
          // console.log(this.userData);
          //calculate the available balanceof the user 
          this.balanceAvailable = this.userData.creditAmount - this.userData.successAmount - this.userData.pendingAmount;
          console.log("balance ava"+this.balanceAvailable)
  
        },
        (error)=>{
          console.log("saas error "+error);
        }
      );
      
      this.authService.getSaasUserOtherInfo(curentUser.userCode).subscribe(
        (res)=>{
          this.userOtherInfo = res.data;
          // console.log("user logged in : "+ JSON.stringify(this.userOtherInfo));
        },
        (error)=>{
          console.log("Error getting logged in user : "+ JSON.stringify(error));
        }
      );
    }else{
      if(curentUser.userType == "SAAS_USER"){
        this.isSaasUser = true;
       }
       this.authService.getSaasUserGeneralAmountInfo(curentUser.userCode).subscribe(
        (res)=>{
     
          this.userData = res.data;
          // console.log(this.userGeneralInfo);
  
          //calculate the available balanceof the user 
          this.balanceAvailable = this.userData.creditAmount - this.userData.successAmount - this.userData.pendingAmount;
  
        },
        (error)=>{
          //console.log(error);
        }
      );
       this.authService.getSaasUserOtherInfo(curentUser.userCode).subscribe(
        (res)=>{
          //console.log(res.data);
          this.userOtherInfo = res.data;
        },
        (error)=>{
          //console.log(error);
        }
      );
    }

    

    let foundAuth: boolean = false;
    item.autorizations.forEach((auth) => {
      if (curentUser.authorities.find(authorization => authorization.authority == auth)) {
        foundAuth = true;
      }
    });
    return foundAuth;
    
  }


  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if(menuItem.childrenPath && menuItem.childrenPath.includes(location.hash.substring(1)))
        menuItem.active = true
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

}
