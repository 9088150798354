// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// import { EncryptStorage } from 'encrypt-storage';

// export const encryptStorage = new EncryptStorage('Digit@l-M@rketing-key-2022', {
//   encAlgorithm: 'Rabbit',
//   storageType: 'localStorage',
// });

export const environment = {
  production: false,
  firebase: {
    apiKey: "Your Api Ke",
    authDomain: "Your Auth Domain",
    databaseURL: "Your Database Url",
    projectId: "Your Project Id",
    storageBucket: "Your StorageBucket url",
    messagingSenderId: "Your Sender Id"
  },
  //baseURL: 'http://backend-dm.nbikodigicom.fr/'
  // baseURL: 'http://10.3.0.4:8383/'
  //baseURL: 'http://172.16.10.201:8383/'
 //baseURL: 'http://localhost:8383/'
  //baseURL: 'http://173.249.7.147:8383/'
  // baseURL: 'http://backend-dm.nbikodigicom.fr/'
  // baseURL: 'https://backend-dm.nbikodigicom.fr/'
  // baseURL: 'https://digital-marketing-dashboard.nbikodigicom.fr/'
 //baseURL: 'https://campaign-backend.connecttechnology.io/'
 baseURL: 'https://api-digital-marketing.connecttechnology.io/'
  // baseURL: 'http://95.111.225.198:83/'
  // baseURL: 'http://10.3.0.2:32628/'
  // baseURL: 'https://backend-dm.nbikodigicom.fr/'
  // baseURL: 'http://10.4.0.132:8383/'

  /* Si vous voulez changer le baseUrl pensez à le changé aussi dans le index.html pour l'affichage de l'icône du site/*/
  
};

