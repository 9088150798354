import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestResult } from '../../model/RequestResult';
import { UserDto } from './../../../components/user/dto/User.dto';
import { UserFormDto } from './../../../components/user/dto/UserForm.dto';
import { UserRegisterDTO } from './../../../components/user/dto/UserRegisterDTO';
import { LoginFormDto } from './../../../shared/dto/loginForm.dto';
import { environment } from '../../../../environments/environment';
import { UrlApiServer } from '../../model/UrlApiServer';
import { CheckAdmin } from '../../dto/checkAdmin';
import { UserAmountInfoDTO } from 'src/app/components/billing/dto/UserBillDTO';
import { OperatorCountryDTO } from 'src/app/components/operator/dto/OperatorCountryDTO';
import { ApplicationInfoDTO } from 'src/app/components/user/dto/AdministratorDTO';


@Injectable({
  providedIn: 'root'
})
export class AuthRemoteService {

  constructor(private http:HttpClient) { }

  createSuperAdmin(userFormDto:UserFormDto):Observable<RequestResult<UserDto>>{
    return this.http.post<RequestResult<UserDto>>(environment.baseURL+UrlApiServer.URL_CREATE_SUPER_ADMIN, userFormDto);
  }

  signUpAsSaasUser(userFormDto:UserRegisterDTO):Observable<RequestResult<UserRegisterDTO>>{
    return this.http.post<RequestResult<UserRegisterDTO>>(environment.baseURL+UrlApiServer.URL_TO_SIGN_UP_AS_SAAS_USER, userFormDto);
  }

  login(loginForm:LoginFormDto):Observable<RequestResult<UserDto>>{
    return this.http.post<RequestResult<UserDto>>(environment.baseURL+UrlApiServer.URL_LOGIN, loginForm);
  }
  
  initialisationCheckState():Observable<RequestResult<string>>{
    return this.http.get<RequestResult<string>>(environment.baseURL+UrlApiServer.URL_INITIALISATION_CHECKSTATE);
  }

  verify_email(token: string): Observable<RequestResult<string>> {
    return this.http.post<RequestResult<string>>(
      environment.baseURL + UrlApiServer.URL_VERIFY_EMAIL,
      { token: token }
    );
  }


  forgotPassword(email: string): Observable<RequestResult<string>> {
    return this.http.post<RequestResult<string>>(
      environment.baseURL + UrlApiServer.URL_FORGOT_PASSWORD,
      { userEmail: email }
    );
  }

  reset_password(token: string, password: string): Observable<RequestResult<string>> {
    return this.http.post<RequestResult<string>>(
      environment.baseURL + UrlApiServer.URL_RESET_PASSWORD,
      { token: token, password: password }
    );
  }

  changePassword(currentPassword: string, newPassword: string): Observable<RequestResult<UserDto>> {
    return this.http.put<RequestResult<UserDto>>(
      environment.baseURL + UrlApiServer.URL_UPDATE_PASSWORD,
      { lastPassword: currentPassword, newPassword: newPassword }
    );
  }

  updateUser(userDto: UserDto): Observable<RequestResult<UserDto>> {
    return this.http.put<RequestResult<UserDto>>(
      environment.baseURL + UrlApiServer.URL_UPDATE_PROFILE_USERS,
      userDto
    );
  }
  getSaasUserGeneralAmountInfo(userCode: string): Observable<RequestResult<UserAmountInfoDTO>>{
    return this.http.get<RequestResult<UserAmountInfoDTO>>(
      environment.baseURL + UrlApiServer.URL_UPDATE_USER_BILING_NEGATIVITY_INFO+'/'+userCode
    );
  }
 
  getApplicationInfo(): Observable<RequestResult<ApplicationInfoDTO>>{
    return this.http.get<RequestResult<ApplicationInfoDTO>>(
      environment.baseURL + UrlApiServer.URL_GET_APPLICATION_INFO
    );
  }
  getSaasUserOtherInfo(userCode: string): Observable<RequestResult<UserDto>>{
    return this.http.get<RequestResult<UserDto>>(
      environment.baseURL + UrlApiServer.URL_GET_SAASUSER_INFO+'/'+userCode
    );
  }
  getSaasUserOperatorCountries(operatorId: number): Observable<RequestResult<OperatorCountryDTO[]>>{
    return this.http.get<RequestResult<OperatorCountryDTO[]>>(
      environment.baseURL + UrlApiServer.URL_GET_ALL_OPERATOR_COUNTRIES_BY_ID+'/'+operatorId
    );
  }
  

}
