import { OperatorCountryDTO } from "../../operator/dto/OperatorCountryDTO";

export class OperatorDTO {
    id: number;
    name:string = '';
    operatorType: string;
    description: string = '';
}

export class UserOperatorDTO{
  
  creditQuantity: number;
  expirationDate: Date;
  failedQuantity: number;
  id: number;
  lastPaymentDate: Date;
  negativeBalanceAllowed: true;
  negativeBalanceQuantity: number;
  operatorDTO: OperatorDTO;
  pendingQuantity: number;
  priority: number;
  saasUserCode: string;
  senderName: string;
  successQuantity: number;
  unitCost: number;
  operatorCountriesDTO: any[]=[];
}

//new class linked to operator_type
export declare enum OperatorType{
  SMS,
  EMAIL,
  WHATSAPP,
  FACEBOOK,
}