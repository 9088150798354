import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {
      breadcrumb: "Dashboard"
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/user/user.module').then(m => m.UserModule),
    data: {
      breadcrumb: "User"
    }
  },
  {
    path: 'contacts',
    loadChildren: () => import('../../components/contact/contact.module').then(m => m.ContactModule),
    data: {
      breadcrumb: "Contacts"
    }
  },
  {
    path: 'profile',
    loadChildren: () => import('../../components/profile/profile.module').then(m => m.ProfileModule),
    data: {
      breadcrumb: "Profile"
    }
  },
  {
    path: 'operator',
    loadChildren: () => import('../../components/operator/operator.module').then(m => m.OperatorModule),
    data: {
      breadcrumb: "Operator"
    }
  },
  {
    path: 'country',
    loadChildren: () => import('../../components/country/country.module').then(m => m.CountryModule),
    data: {
      breadcrumb: "Country"
    }
  },
    {
    path: 'currency',
    loadChildren: () => import('../../components/currency/currency.module').then(m => m.CurrencyModule),
    data: {
      breadcrumb: "Currency"
    }
  },
  {
    path: 'campaign',
    loadChildren: () => import('../../components/campaign/campaign.module').then(m => m.CampaignModule),
    data: {
      breadcrumb: "Campaign"
    }
  },
  {
    path: 'billing',
    loadChildren: () => import('../../components/billing/billing.module').then(m => m.BillingModule),
    data: {
      breadcrumb: "Billing"
    }
  },
  {
    path: 'tariff',
    loadChildren: () => import('../../components/tariff/tariff.module').then(m => m.TariffModule),
    data: {
      breadcrumb: "Tariff"
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('../../components/reload-system/reload-system.module').then(m => m.ReloadSystemModule),
    data: {
      breadcrumb: "Settings"
    }
  }
];