import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';

import { filter } from 'rxjs/operators';
import { map } from 'rxjs/internal/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs: { parentBreadcrumb?: any; childBreadcrumb?: any; };
  public title: Promise<string>;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router, private translate: TranslateService) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe(route => {
        let snapshot = this.router.routerState.snapshot;
        let title: string = route.snapshot.data['title'];
        let parent = route.parent.snapshot.data['breadcrumb'];
        let child = route.snapshot.data['breadcrumb'];

        this.title = <Promise<string>> translate.get(route.snapshot.data['title']).toPromise().then((res)=>{return res});
        parent = <Promise<string>> translate.get(route.parent.snapshot.data['breadcrumb']).toPromise().then((res)=>{return res});
        child = <Promise<string>> translate.get(route.snapshot.data['breadcrumb']).toPromise().then((res)=>{return res});
        
        this.breadcrumbs = {};
        this.breadcrumbs = {
          "parentBreadcrumb": parent,
          "childBreadcrumb": child
        }

      
      });
  }

  ngOnInit() {  }

  ngOnDestroy() {  }

}
