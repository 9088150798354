import { RoleDto } from './Role.dto';
export class AdministratorDTO {
  userNames:string;
  userEmail:string;
  roles:RoleDto[];
  userPhoneNumber:string;
}

export class ApplicationInfoDTO {
  appCopyRightName: string
  companyName: string
  logoImagePath: string
  logoName: string
}
