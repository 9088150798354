
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/authService/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminCreated implements CanActivate {

constructor(private router:Router,private authService:AuthService) { }

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):  boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>
   {



  return this.getInitialisationCheck(state);

   // throw new Error('Method not implemented.');
  }

  getInitialisationCheck(state: RouterStateSnapshot): Promise<boolean>{
    let schoolnum: Promise<boolean> = <Promise<boolean>> this.authService.verifIfSystemInitialised()
    .toPromise()
    .then(
      (result) => {
        if(result.data != 'SYSTEM_ALREADY_INIT') {
          return true;
        }
        else {
          this.router.navigate(['/auth/login']);
          return false;
        }
      }

    );
    return schoolnum;
  }

}
