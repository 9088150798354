import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/authService/auth.service';



@Injectable({
    providedIn: 'root'
})

export class SecureInnerPagesGuard implements CanActivate {
    constructor(private router:Router,private authService:AuthService) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.getInitialisationCheck(state);
        
    }


    getInitialisationCheck(state: RouterStateSnapshot): Promise<boolean>{
        let schoolnum: Promise<boolean> = <Promise<boolean>> this.authService.verifIfSystemInitialised()
        .toPromise()
        .then(
          (result) => {
            if(result.data == 'SYSTEM_ALREADY_INIT') {
              return true;
            }
            else {
              localStorage.clear();
              this.router.navigate(['/auth/sup-admin']);
              return false;
            }
          }
    
        );
        return schoolnum;
      }
}