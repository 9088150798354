import { Component, OnInit } from '@angular/core';
import { ApplicationInfoDTO } from 'src/app/components/user/dto/AdministratorDTO';
import { AuthService } from '../../services/authService/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  appInfo:ApplicationInfoDTO=new ApplicationInfoDTO()
  currentYear:number=new Date().getFullYear()
  
  constructor(private auth:AuthService) { }
 
  ngOnInit() {
    console.log("appinfo ")
    this.auth.getApplicationInfo().subscribe((resp:any)=>{
      if(resp)
      this.appInfo=resp.data
      console.log("appinfo2 "+(JSON.stringify(this.appInfo)))
    },(error:any)=>{
      console.log("error "+error.error.message)
    })
   }

}
