import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserDto } from 'src/app/components/user/dto/User.dto';
import { UserAdminService } from 'src/app/components/user/services/administrator/user-admin.service';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { AuthService } from '../services/authService/auth.service';
import { Menu, NavService } from '../services/nav.service';

@Injectable({
  providedIn: 'root'
})
export class SecureAuthPagesGuard implements CanActivate {
  curentUser: UserDto = JSON.parse(localStorage.getItem('userData'));
  constructor(private router:Router, private navServices: NavService, private userService: UserAdminService, private authService: AuthService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkAuthMenu(state).then((val)=>{
      
      if(val){
        return true;
      }
      else{
        this.router.navigate(['/users/edit-profile']);
        return false;
      }
    });
  }

  checkAuthMenu(stateOfRoute: RouterStateSnapshot): Promise<boolean>{
          localStorage.setItem('recentPath', location.hash.substring(1));
          let result = new Promise<boolean>(
              (resolve)=>{

                this.reloadUserInfo();
                
                let menu = this.navServices.MENUITEMS;

                var item = menu.find(currentMenu => currentMenu.path == stateOfRoute.url);
                if(!item){
                  item = menu.find(currentMenu => currentMenu.childrenPath.find(child=> child == stateOfRoute.url) == stateOfRoute.url);
                }
                if(this.checkIfUserAuthorized(item)){ resolve(true);}
                else{resolve(false); }
              });

              return result;
        }
      
    
    reloadUserInfo(){
      this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
         if (event.id === 1 && event.url === event.urlAfterRedirects) {
          //if (event.id === 1) {
            this.userService.getUserInfo().toPromise().then(
              (res)=>{
                this.authService.addUserToLocalStorage(res.data);
              }
            );
        }
      });
    }

 checkIfUserAuthorized(item: Menu): boolean{
    let foundAuth: boolean = false;
    item.autorizations.forEach((auth) => {
      if (this.curentUser.authorities.find(authorization => authorization.authority == auth)){
        foundAuth = true;
      }
    });
    return foundAuth;
  }
  
}
