import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthRemoteService } from './auth-remote.service';
import { AuthorisationGroupBean } from '../../dto/AuthorisationGroup.bean';
import { UserDto } from '../../../components/user/dto/User.dto';
import { LoginFormDto } from '../../../shared/dto/loginForm.dto';
import { UserFormDto } from '../../../components/user/dto/UserForm.dto';
import { RequestResult } from '../../../shared/model/RequestResult';
import { CookieService } from 'ngx-cookie-service';
import { UserRegisterDTO } from 'src/app/components/user/dto/UserRegisterDTO';
import { UserAmountInfoDTO } from 'src/app/components/billing/dto/UserBillDTO';
import { OperatorCountryDTO } from 'src/app/components/operator/dto/OperatorCountryDTO';
import { ApplicationInfoDTO } from 'src/app/components/user/dto/AdministratorDTO';
//import {encryptStorage} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private http: HttpClient;
  userAuthorisationGroupBean:AuthorisationGroupBean[]=[];
  showLoader: boolean = false;
  //private userSubject: BehaviorSubject<any>;

  constructor(public router: Router, handler: HttpBackend,private authRemote:AuthRemoteService, private cookieService: CookieService) { 
      this.http = new HttpClient(handler);
  }


  createSuperAdmin(userFormDto:UserFormDto):Observable<RequestResult<UserDto>>{
    return  this.authRemote.createSuperAdmin(userFormDto);
  }

  signUpAsSaasUser(userFormDto:UserRegisterDTO):Observable<RequestResult<UserRegisterDTO>>{
    return this.authRemote.signUpAsSaasUser(userFormDto);
  }

  login(loginFormDto:LoginFormDto):Observable<RequestResult<UserDto>> {
    return this.authRemote.login(loginFormDto);
  }


  addTokenToLocalStorage(token:string){
    localStorage.removeItem('userToken');
    localStorage.setItem('userToken',token);
  }

  removeTokenToLocalStorage() {
    localStorage.removeItem('userToken');
  }

  getTokenToLocalStorage():string{
    return localStorage.getItem('userToken');
  }

  addUserToLocalStorage(userDto:UserDto){
    userDto.userPassword = '';
    userDto.password = '';
    // encryptStorage.setItem('user', JSON.stringify(userDto));
    // let user=JSON.parse(encryptStorage.decryptString(encryptStorage.getItem('user')));
    // //console.log(user);
    localStorage.setItem('userData',JSON.stringify(userDto));
  }


  removeUserToLocalStorage(){
    localStorage.removeItem('userData');
  }

  getUserFromLocalStorage():UserDto{
    let user=JSON.parse(localStorage.getItem('userData'));
    return user;
  }


  getAuthorisationUser(){
    let user=this.getUserFromLocalStorage();
  }

  SignOut() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.showLoader = false;
    localStorage.clear();
    //this.cookieService.deleteAll('user', '/auth/login');
    location.reload();
    setTimeout(()=>{
      this.router.navigate(['/auth/login']);
    },500);
    
      return true;
  }


  verifIfSystemInitialised():Observable<RequestResult<string>>{
    return this.authRemote.initialisationCheckState()
  }

  verify_email(token:string): Observable<RequestResult<string>> {
     return this.authRemote.verify_email(token);
  }

  forgot_password(email:string): Observable<RequestResult<string>> {
    return this.authRemote.forgotPassword(email);
  }

  reset_password(token:string,password:string): Observable<RequestResult<string>>{
    return this.authRemote.reset_password(token,password);
}

changePassword(currentPassword,newPassword): Observable<RequestResult<UserDto>>{
  return this.authRemote.changePassword(currentPassword,newPassword);

}

updateUserProfile(userDto: UserDto): Observable<RequestResult<UserDto>> {
  return this.authRemote.updateUser(userDto);
}
getSaasUserGeneralAmountInfo(userCode : string): Observable<RequestResult<UserAmountInfoDTO>>{
  return this.authRemote.getSaasUserGeneralAmountInfo(userCode);
}
getSaasUserOtherInfo(userCode : string): Observable<RequestResult<UserDto>>{
  return this.authRemote.getSaasUserOtherInfo(userCode);
}

getSaasUserOperatorCountries(operatorId: number): Observable<RequestResult<OperatorCountryDTO[]>>{
  return this.authRemote.getSaasUserOperatorCountries(operatorId);
}
getApplicationInfo(): Observable<RequestResult<ApplicationInfoDTO>>{
  return this.authRemote.getApplicationInfo();
}



}
