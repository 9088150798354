export class UrlApiServer {

  //address
  static URL_CREATE_ADDRESS = '/api/addresses/create';
  static URL_DELETE_ADRRESS = '/api/addresses/delete';
  static URL_SEARCH_ADDRESS = '/api/addresses/search';
  static URL_SEARCH_PAGINATE = '/api/addresses/searchPaginate';
  static URL_UPDATE_ADDRESS = '/api/addresses/update';

  //auth
  static URL_CREATE_SUPER_ADMIN="auth/createSuperAdmin";
  static URL_LOGIN="auth/login";
  static URL_VERIFY_EMAIL = 'auth/verifyEmail';
  static URL_RESET_PASSWORD = 'auth/resetPassword';
  static URL_FORGOT_PASSWORD = 'auth/sendTokenResetPassword/dashboard';
  static URL_SEND_TOKEN_VERIFY_EMAIL='auth/sendTokenVerifyEmail/dashboard';
  static URL_UPDATE_PASSWORD='api/users/myCredential';
  static URL_GET_APPLICATION_INFO='auth/applicationInfo';

  //authorizations
  static URL_GET_ALL_AUTHORIZATIONS = '/api/authorizations/all';
  static URL_AUTHORIZATIONS_INITIALISATION = '/api/authorizations/appInitialization';


  // system-initialisation
  static URL_INITIALISATION_CHECKSTATE="auth/systemInitializationStatus";

  static URL_RELOAD_APP="api/authorizations/appInitialization";

  //billing
  static URL_GET_BILLING_BY_ID = '/api/billing/';
  static URL_DELETE_BILLING_BY_ID = '/api/billing/';
  static URL_CANCEL_BILLING_BY_ID = '/api/billing/canceled/';
  static URL_CREATE_BILLING= '/api/billing/create';
  static URL_PUT_BILLING_IN_PROCESSING_STATUS= '/api/billing/processing/';
  static URL_REJECT_BILING= '/api/billing/rejected/';
  static URL_SEARCH_BILING= '/api/billing/search';
  static URL_SEARCH_BILING_PAGINATE= '/api/billing/searchPaginate';
  //This api allow to update user amount negativity info
  static URL_UPDATE_USER_BILING_NEGATIVITY_INFO= 'api/billing/userAmount';
  static URL_CREDIT_USER_BILLING= 'api/billing/userAmount/credit';
  static URL_UPDATE_USER_BILING_INFO = 'api/billing/userAmount/updateInfo';
  //This api allow to get information between a particular saas user and a specific operator
  static URL_GET_USER_AND_OPERATOR_INFO = '/api/billing/userOperator/';
  //This api allow to add operator to a saas user in amount mode
  static URL_ADD_OPERATOR_TO_SASS_USER = 'api/billing/userOperator/createAmountMode';
  ///api/billing/userOperator/updateInfo
  static URL_UPDATE_USER_OPERATOR = '/api/billing/userOperator/updateInfo';
  //This api allow to get all information between a particular saas user and his operators
  static URL_GET_INFO_OF_SASS_USER_OPERATORS = 'api/billing/userOperators';
  static URL_DELETE_SAAS_USER_OPERATOR = 'api/billing/userOperator/delete';

  
  //user
  static URL_CREATE_USER="api/users/create";
  static URL_CURRENT_USER="api/users/connectedUser";
  static URL_DELETE_USERS="api/users/delete";
  static URL_UPDATE_USERS="api/users/update";
  static URL_UPDATE_PROFILE_USERS="api/users/myProfile";
  static URL_ACTIVATE_USER="api/users/activate";
  static URL_DESACTIVATE_USER="api/users/deactivate";
  static URL_GET_ALL_USER_BY_SEARCH = 'api/users/search';
  static URL_GET_ALL_USER_WITH_PAGINATE = 'api/users/searchPaginate';

   //roles
  static URL_ALL_ROLE="api/roles/all";
  static URL_UPDATE_ROLE="api/roles/update";
  static URL_CREATE_ROLE="api/roles/create";
  static URL_DELETE_ROLE="api/roles/delete";
  static URL_GET_ALL_AUTHORISATION="api/authorizations/all";
  static URL_ALL_ROLE_BY_SEARCH = 'api/roles/search';
  static URL_ALL_ROLE_WITH_PAGINATE = 'api/roles/searchPaginate';


   //Registrations
  static URL_TO_SIGN_UP_AS_SAAS_USER='api/saasUserApplication/create';
  static URL_UPDATE_REGISTRATION="api/saasUserApplication/update";
  static URL_DELETE_REGISTRATION="api/saasUserApplication/delete";
  static URL_VALIDATE_REGISTRATION ="api/saasUserApplication/validate";
  static URL_ALL_REGISTRATION_WITH_PAGINATE="api/saasUserApplication/searchPaginate";
  static URL_ALL_REGISTRATION="api/saasUserApplication/search";



   //SaaS user
  static URL_ADD_SAAS_USER_SENDERNAME = 'api/saasUsers/addSenderName';
  static URL_GET_SAASUSER_INFO = 'api/saasUsers/get';
  //takes saas user code as param
  static URL_GET_SAAS_USER_SENDERNAMES = 'api/saasUsers/senderNames';
  static URL_GET_SAAS_USER_CURRENCY = 'api/saasUsers/currency';
  static URL_DELETE_SAAS_USER_CURRENCY = 'api/saasUsers/currency/delete';
  static URL_DELETE_SAAS_USER_SENDER_NAME = 'api/saasUsers/deleteSenderName';
  //takes an id as parameter
  static URL_GET_ALL_SAAS_USER_CURRENCIES= 'api/saasUsers/currencies';
  static URL_SAVE_SAAS_USER_CURRENCY = 'api/saasUsers/currency/save';
  static URL_CREATE_SAAS_USER="api/saasUsers/create";
  static URL_DELETE_SAAS_USERS="api/saasUsers/delete";
  static URL_UPDATE_SAAS_USERS="api/saasUsers/update";
  static URL_GET_ONE_SAAS_USERS="api/saasUsers/get";
  static URL_GET_ALL_SAAS_USER_BY_SEARCH = 'api/saasUsers/search';
  static URL_GET_ALL_SAAS_USER_WITH_PAGINATE = 'api/saasUsers/searchPaginate';

    //Guest user
  static URL_CREATE_GUEST_USER="api/guestUsers/create";
  static URL_DELETE_GUEST_USERS="api/guestUsers/delete";
  static URL_UPDATE_GUEST_USERS="api/guestUsers/update";
  static URL_GET_ONE_GUEST_USERS="api/guestUsers/getGuest";
  static URL_GET_GUEST_OF_SAAS_USERS="api/guestUsers/getGuestOfSaasUser";
  static URL_GET_ALL_GUEST_USER_BY_SEARCH = 'api/guestUsers/search';
  static URL_GET_ALL_GUEST_USER_WITH_PAGINATE = 'api/guestUsers/searchPaginate';


    //Import contact
  static URL_IMPORT_CONTACT="api/profile/importContactFromCSV";
  static URL_GET_IMPORT_CONTACT="api/profile/getImport";
  static URL_DELETE_IMPORT_CONTACT="api/profile/deleteImport";
  static URL_CAN_DELETE_IMPORT_CONTACT="api/profile/canBeDeletedImport";
  static URL_GET_SAAS_USER_IMPORT_CONTACT="api/profile/saasUserImports";
  static URL_GET_ALL_IMPORT_CONTACT_BY_SEARCH = 'api/profile/searchImport';
  static URL_GET_ALL_IMPORT_CONTACT_WITH_PAGINATE = 'api/profile/searchPaginateImport';
  static URL_GET_USER_TAGS="api/profile/saasUserTags";


    //Profile
  static URL_SAVE_REST_PROFILE="api/profile/saveProfileRest";
  static URL_SAVE_CSV_PROFILE="api/profile/saveProfileCSV";
  static URL_DELETE_PROFILE="api/profile/deleteProfile";
  static URL_DELETE_CLAUSE="api/profile/deleteClause";
  static URL_DELETE_REST_HEADER="api/profile/deleteRestHeader";
  static URL_GET_REST_PROFILE = 'api/profile/getProfileRest';
  static URL_GET_CSV_PROFILE = 'api/profile/getProfileCSV';
  static URL_GET_ALL_SAASUSER_PROFILE = 'api/profile/saasUserProfiles';
  static URL_GET_ALL_PROFILE_BY_SEARCH = 'api/profile/search';
  static URL_GET_ALL_PROFILE_WITH_PAGINATE = 'api/profile/searchPaginate';
  static URL_TEST_PROFILE_REST = 'api/profile/targetCount';

  static URL_DELTE_CLAUSE = '/api/profile/deleteClause/';
  static URL_GET_CONTACT_FROM_IMPORT = '/api/profile/getContacts/';
  //takes a usercode
  static URL_GET_ALL_PROFILES_FROM_SAAS_USER = 'api/profile/saasUserProfiles/';

    //Campaign
  static URL_GET_CAMPAIGN_FREQUENCIES="api/campaign/campaignFrequencies";
  static URL_GET_CAMPAIGN_STATUS="api/campaign/campaignStatus";
  static URL_GET_CAMPAIGN_STOP_RULES="api/campaign/campaignStopRules";
  static URL_GET_CAMPAIGN_CONTACT_ORDER_CHOICE="api/campaign/contactOrderChoices";
  static URL_DELETE_CAMPAIGN="api/campaign/delete";
  static URL_DELETE_PROFILE_CAMPAIGN="api/campaign/deleteProfile";
  static URL_GET_CAMPAIGN="api/campaign/get";
  static URL_SAVE_CAMPAIGN="api/campaign/save";
  static URL_RESTART_CAMPAIGN="api/campaign/restart";
  static URL_RESUME_CAMPAIGN="api/campaign/resume";
  static URL_STOP_CAMPAIGN="api/campaign/stop";
  static URL_SUSPEND_CAMPAIGN="api/campaign/suspend";
  static URL_GET_ALL_REPORT_CAMPAIGN_FOR_ONE_PROFILE="api/campaign/campaignProfileReports";
  static URL_GET_ALL_REPORT_CAMPAIGN_DETAILS="api/campaign/campaignReportDetails";
  static URL_GET_CAMPAIGN_REPORT_DETAILS_HISTORY = "api/campaign/campaignReportDetailsHistory"
  static URL_GET_ALL_REPORT_CAMPAIGN_FOR_ALL_PROFILE="api/campaign/campaignReports";
  static URL_GET_ALL_CAMPAIGN_BY_SEARCH = 'api/campaign/search';
  static URL_GET_ALL_CAMPAIGN_WITH_PAGINATE = 'api/campaign/searchPaginate';
  
    //Api-campaign
  static URL_GET_API_CAMPAIGN_BALANCE = '/api-campaign/balance';
  static URL_GET_API_CAMPAIGN_CURRENT_RATE = '/api-campaign/currentRate';
  static URL_GET_API_CAMPAIGN_DELIVERY_REPORT = '/api-campaign/dlr';
  static URL_GET_API_CAMPAIGN_DELIVERY_REPORT_BY_ID = '/api-campaign/dlr-s';
  static URL_API_CAMPAIGN_AUTHENTICATE_USER = '/api-campaign/login';
  static URL_API_CAMAIGN_GET_MONEY_BALANCE = '/api-campaign/moneyBalance';
  static URL_GET_ALL_REPORT_FOR_CAMPAIGN_WITH_NO_PROFILES = 'api-campaign/report';
  
  //This api allows users to get their delivery report in details after submit of a campaign 
  static URL_GET_CAMPAIGN_REPORT_DETAILS = 'api/campaign/reportDetails'; 
  static URL_API_CAMAIGN_SEND_MAIL_OPERATOR = '/api-campaign/send-mail-operator';
 //This api allows you to send short message using SMS operators
  static URL_API_CAMPAIGN_SEND_SMS_OPERATOR = '/api-campaign/send-sms';
  //This api allows you to send short message using MTN operator
  static URL_API_CAMPAIGN_SEND_SMS_OPERATOR_USING_MTN = '/api-campaign/send-sms-operator';
  //This api allow to get senderNames of a particular saas user
  static URL_GET_API_CAMPAIGN_SENDER_NAME = '/api-campaign/senderNames';

    //Tariff management
  static URL_SAVE_TARIFF="api/tariff/save";
  static URL_GET_TARIFF_BY_ID="api/tariff";
  static URL_GET_TARIFF_OF_OPERATOR = "api/tariff/operator/";
  static URL_GET_TARIFF_BY_OPERATOR_TYPE="api/tariff/operatorType";
  static URL_DELETE_TARIFF="api/tariff";
  static URL_SEARCH_TARIFF="api/tariff/search";
  static URL_SEARCH_PAGINATE_TARIFF="api/tariff/searchPaginate";

  static URL_GET_ALL_TARIFF_MODES="api/saasUsers/tariffModes";


  //saas user application
  static URL_CREATE_SAAS_USER_APPLICATION = 'api/saasUserApplication/create';
  static URL_DELETE_SAAS_USER_APPLICATION = 'api/saasUserApplication/delete/';
  static URL_GET_SAAS_USER_APPLICATION = 'api/saasUserApplication/get/';
  static URL_SEARCH_SAAS_USER_APPLICATION = 'api/saasUserApplication/search';
  static URL_SEARCH_PAGINATE_SAAS_USER_APPLICATION = 'api/saasUserApplication/searchPaginate';
  static URL_UPDATE_SAAS_USER_APPLICATION = 'api/saasUserApplication/update';
  //takes the id as parameter
  static URL_VALIDATE_SAAS_USER_APPLICATION = 'api/saasUserApplication/validate/';



    //Saas user billing
  static URL_CREATE_BILL="api/billing/create";
  static URL_GET_BILL_BY_ID="api/billing";
  static URL_GET_BALANCE_USER_OPERATOR="api/billing/userOperator";
  static URL_GET_BALANCE_USER="api/billing/userOperators";
  static URL_UPDATE_BALANCE_INFO="api/billing/userOperator/updateInfo";
  static URL_DELETE_BILL="api/billing";
  static URL_CANCEL_BILL="api/billing/canceled";
  static URL_PROCESS_BILL="api/billing/processing";
  static URL_REJECT_BILL="api/billing/rejected";
  static URL_VALIDATE_BILL="api/billing/validated";
  static URL_SEARCH_BILL="api/billing/search";
  static URL_SEARCH_PAGINATE_BILL="api/billing/searchPaginate";
 


    //Operator
  static URL_GET_COUNTRIES = 'api/operator/countries';
  static URL_GET_COUNTRY_BY_ID = 'api/operator/country';
  static URL_DELETE_OPERATOR_COUNTRY = 'api/operator/country/delete';
  static URL_SAVE_AND_UPDATE_COUNTRY = 'api/operator/country/save';
  static URL_DELETE_OPERATOR_BY_ID = 'api/operator/deleteOperator';
  static URL_GET_OPERATOR_EMAIL_BY_ID = 'api/operator/getOperatorEMAIL';
  static URL_GET_OPERATOR_WHATSAPP_BY_ID = 'api/operator/getOperatorWhatsapp';
  static URL_GET_OPERATOR_TELEGRAM_BY_ID = 'api/operatorTelegram';
  static URL_GET_OPERATOR_BY_SAAS_USER = 'api/operator/saasUserOperators';

  static URL_GET_OPERATOR_GATEWAYS = 'api/operator/gateways';
  
  static URL_GET_OPERATOR_SMS_BY_ID = 'api/operator/getOperatorSMS';
  static URL_GET_ALL_OPERATORS_LIST="api/operator/list";
  static URL_GET_ALL_OPERATORS_SEARCH_PAGINATE="api/operator/searchPaginate";
  static URL_GET_ALL_OPERATOR_COUNTRIES_BY_ID="api/operator/operatorCountries/operator";
  static URL_GET_ALL_OPERATOR_COUNTRIES_BY_COUNTRY_ID="api/operator/operatorCountries/country";
  static URL_GET_AN_OPERATOR_COUNTRY_BY_ID="api/operator/operatorCountry";

  static URL_DELETE_AN_OPERATOR_COUNTRY_BY_ID="api/operator/operatorCountry/delete";
  static URL_DELETE_COUNTRY_BY_ID="api/operator/country/delete";

  static URL_SAVE_AN_OPERATOR_COUNTRY_BY_ID="api/operator/operatorCountry/save";
  static URL_GET_OPERATOR_PHONE_NUMBER_PREFIX="api/operator/phoneNumberPrefix";
  static URL_GET_SASS_USER_OPERATORS="api/operator/saasUserOperators";
  static URL_SAVE_OPERATOR_OF_TYPE_EMAIL="api/operator/saveOperatorEMAIL";
  static URL_SAVE_OPERATOR_OF_TYPE_WHATSAPP="api/operator/saveOperatorWhatsapp";
  static URL_SAVE_OPERATOR_OF_TYPE_TELEGRAM="api/operator/saveOperatorTelegram";
  static URL_SAVE_OPERATOR_OF_TYPE_SMS="api/operator/saveOperatorSMS";
  static URL_SEARCH_OPERATOR="api/operator/search";
  static URL_SEARCH_PAGINATE_OPERATOR="api/operator/searchPaginate";
  static URL_GET_OPERATORS_TYPE_LIST="api/operator/type/list";


  static URL_GET_ALL_OPERATOR="api/operator/list";


  //Dashboard
  static URL_GET_USER_STATS="api/dashboard/user-stats";
  static URL_GET_CAPAIGN_STATS="api/dashboard/campaign-stats"
  static URL_FILTER_DASHBOARD="api/dashboard/report-details-stats"
  static URL_DETAILS_DASHBOARD="api/dashboard/report-details-stats-details"

}
