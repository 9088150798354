import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserOperatorDTO } from 'src/app/components/contact/dto/operatorDTO';
import { RequestResult } from 'src/app/shared/model/RequestResult';
import { UrlApiServer } from 'src/app/shared/model/UrlApiServer';
import { environment } from '../../../../../environments/environment';
import { RequestResultPagination } from '../../dto/RequestResultPagination';
import { UserDto } from '../../dto/User.dto';
import { UserFormDto, UserOperatorInfoDTO } from '../../dto/UserForm.dto';

@Injectable({
  providedIn: 'root'
})
export class UserAdminRemoteService {

  constructor(private http: HttpClient) { }

  activateUser(userCode: string): Observable<RequestResult<any>> {
    return this.http.get<RequestResult<any>>(
      environment.baseURL + UrlApiServer.URL_ACTIVATE_USER+'/'+userCode
    );
  }

  getUserInfo(): Observable<RequestResult<UserDto>> {
    return this.http.post<RequestResult<UserDto>>(
      environment.baseURL + UrlApiServer.URL_CURRENT_USER, null
    );
  }

  desactivateUser(userCode: string): Observable<RequestResult<any>> {
    return this.http.get<RequestResult<any>>(
      environment.baseURL + UrlApiServer.URL_DESACTIVATE_USER+'/'+userCode
    );
  }

//-----------------------------------------------------------Admin API-------------------------------------------

  createAdmin(userFormDto: UserFormDto): Observable<RequestResult<any>> {
    return this.http.post<RequestResult<any>>(
      environment.baseURL + UrlApiServer.URL_CREATE_USER,
      userFormDto
    );
  }

  addOperatorToSaasUser(userOperatorInfoDTO: UserOperatorInfoDTO): Observable<RequestResult<UserOperatorDTO>>{
    return this.http.post<RequestResult<UserOperatorDTO>>(
      environment.baseURL+ UrlApiServer.URL_ADD_OPERATOR_TO_SASS_USER, userOperatorInfoDTO
    );
  }
 

  getAllAdmin(search: string): Observable<RequestResult<UserDto[]>> {
    let params = new HttpParams();
    params = params.append('search', search);

    return this.http.get<RequestResult<UserDto[]>>(
      environment.baseURL + UrlApiServer.URL_GET_ALL_USER_BY_SEARCH, { params: params }
    );
  }

  getAllAdminWithPagination(currentPage: number, pageSize: number,search:string): Observable<RequestResult<RequestResultPagination<UserDto[]>>> {

    let params = new HttpParams();
  
    params = params.append('page', currentPage.toString());
    params = params.append('size', pageSize.toString());
    params = params.append('search', search);
  
    return this.http.get<RequestResult<RequestResultPagination<UserDto[]>>>(environment.baseURL+UrlApiServer.URL_GET_ALL_USER_WITH_PAGINATE,{params:params});
  
  }

  updateAdmin(userDto: UserDto): Observable<RequestResult<UserDto>> {
    return this.http.put<RequestResult<UserDto>>(
      environment.baseURL + UrlApiServer.URL_UPDATE_USERS, userDto
    );
  }

  deleteAdmin(userCode: string): Observable<RequestResult<UserDto>> {
    let params = new HttpParams();
    params = params.append('userCodeToDelete', userCode);

    return this.http.delete<RequestResult<UserDto>>(
      environment.baseURL + UrlApiServer.URL_DELETE_USERS, { params: params }
    );
  }
  deleteOperatorFromSaasUser(id: number ): Observable<RequestResult<boolean>> {
    return this.http.delete<RequestResult<boolean>>(
      environment.baseURL + UrlApiServer.URL_DELETE_SAAS_USER_OPERATOR+'/'+id
    );
  }


  //----------------------------------------------------------Saas User API----------------------------------------------

  createSaaSUser(userFormDto: any): Observable<RequestResult<any>> {
    return this.http.post<RequestResult<any>>(
      environment.baseURL + UrlApiServer.URL_CREATE_SAAS_USER,
      userFormDto
    );
  }
  createSaaSUserApplication(userFormDto: any): Observable<RequestResult<any>> {
    return this.http.post<RequestResult<any>>(
      environment.baseURL + UrlApiServer. URL_CREATE_SAAS_USER_APPLICATION ,
      userFormDto
    );
  }
  addSenderNameOfSaasUser(senderNameToAddObject: any): Observable<RequestResult<any>> {
    return this.http.post<RequestResult<any>>(
      environment.baseURL + UrlApiServer. URL_ADD_SAAS_USER_SENDERNAME ,
      senderNameToAddObject
    );
  }

  getAllSaaSUser(search: string): Observable<RequestResult<UserDto[]>> {
    let params = new HttpParams();
    params = params.append('search', search);

    return this.http.get<RequestResult<UserDto[]>>(
      environment.baseURL + UrlApiServer.URL_GET_ALL_SAAS_USER_BY_SEARCH, { params: params }
    );
  }

  getAllSaaSUserWithPagination(currentPage: number, pageSize: number,search:string): Observable<RequestResult<RequestResultPagination<UserDto[]>>> {

    let params = new HttpParams();
  
    params = params.append('page', currentPage.toString());
    params = params.append('size', pageSize.toString());
    params = params.append('search', search);
  
    return this.http.get<RequestResult<RequestResultPagination<UserDto[]>>>(environment.baseURL+UrlApiServer.URL_GET_ALL_SAAS_USER_WITH_PAGINATE,{params:params});
  
  }

  updateSaaSUser(userDto: UserDto): Observable<RequestResult<UserDto>> {
    return this.http.put<RequestResult<UserDto>>(
      environment.baseURL + UrlApiServer.URL_UPDATE_SAAS_USERS, userDto
    );
  }
  getSaaSUserOnDB(userCode: string): Observable<RequestResult<UserDto>> {
    return this.http.get<RequestResult<UserDto>>(
      environment.baseURL + UrlApiServer.URL_GET_ONE_SAAS_USERS+'/'+userCode
    );
  }

  deleteSaaSUser(userCode: string): Observable<RequestResult<UserDto>> {
    return this.http.delete<RequestResult<UserDto>>(
      environment.baseURL + UrlApiServer.URL_DELETE_SAAS_USERS+"/"+userCode);
  }
  
  getSaasUserSenderNames(userCode: any): Observable<RequestResult<any>>{
    return this.http.get<RequestResult<any>>(
      environment.baseURL + UrlApiServer.URL_GET_SAAS_USER_SENDERNAMES+"/"+userCode
      );
  }
    
    
  deleteSaasUserSenderName(id: number): Observable<RequestResult<boolean>> {
    return this.http.delete<RequestResult<boolean>>(
      environment.baseURL + UrlApiServer.URL_DELETE_SAAS_USER_SENDER_NAME+"/"+id);
  }



  //----------------------------------------------------------Guest User API----------------------------------------------

  createguestUser(userFormDto: UserFormDto): Observable<RequestResult<any>> {
    return this.http.post<RequestResult<any>>(
      environment.baseURL + UrlApiServer.URL_CREATE_GUEST_USER,
      userFormDto
    );
  }

  getAllguestUser(search: string): Observable<RequestResult<UserDto[]>> {
    let params = new HttpParams();
    params = params.append('search', search);

    return this.http.get<RequestResult<UserDto[]>>(
      environment.baseURL + UrlApiServer.URL_GET_ALL_GUEST_USER_BY_SEARCH, { params: params }
    );
  }

  getAllguestUserWithPagination(currentPage: number, pageSize: number,search:string): Observable<RequestResult<RequestResultPagination<UserDto[]>>> {

    let params = new HttpParams();
  
    params = params.append('page', currentPage.toString());
    params = params.append('size', pageSize.toString());
    params = params.append('search', search);
  
    return this.http.get<RequestResult<RequestResultPagination<UserDto[]>>>(environment.baseURL+UrlApiServer.URL_GET_ALL_GUEST_USER_WITH_PAGINATE,{params:params});
  
  }

  updateguestUser(userDto: UserDto): Observable<RequestResult<UserDto>> {
    return this.http.post<RequestResult<UserDto>>(
      environment.baseURL + UrlApiServer.URL_UPDATE_GUEST_USERS, userDto
    );
  }
  getguestUserOnDB(userCode: string): Observable<RequestResult<UserDto>> {
    return this.http.get<RequestResult<UserDto>>(
      environment.baseURL + UrlApiServer.URL_GET_ONE_GUEST_USERS+'/'+userCode
    );
  }

  getguestOfSaaSUser(saasUserCode: string): Observable<RequestResult<UserDto>> {
    return this.http.get<RequestResult<UserDto>>(
      environment.baseURL + UrlApiServer.URL_GET_GUEST_OF_SAAS_USERS+'/'+saasUserCode
    );
  }

  deleteguestUser(userCode: string): Observable<RequestResult<UserDto>> {
    return this.http.get<RequestResult<UserDto>>(
      environment.baseURL + UrlApiServer.URL_DELETE_GUEST_USERS+"/"+userCode);
  }

}
