import { Injectable, HostListener, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';



// Menu
export interface Menu {
	id?: number,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	autorizations?: string[],
	childrenPath?: string[]
}

@Injectable({
	providedIn: 'root'
})

export class NavService  {

	public screenWidth: any
	public collapseSidebar: boolean = false
	public fullScreen = false;
	value:string="noValue"
	lang!:string
	constructor(private translate: TranslateService) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}
	traductor(variable :string):string{
		console.log("Success translation ts test1")
		this.lang=localStorage.getItem('lang')
		console.log("lang "+this.lang)
		this.translate.use(this.lang);
		this.translate
      .get('Dashboard')
      .subscribe((successMessage: string) => {
		console.log("Success translation ts")
        console.log("Success translation ts test2"+successMessage);
		this.value= successMessage

      });
	  console.log("Success translation ts test3")
	  return this.value
	}
	changeLanguageMenu(lang:string){
		this.lang=lang
		
		return lang
	}
	

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	public MENUITEMS: Menu[] = [
		{
			id: 1, path: '/dashboard', title: this.translate.instant('Dashboard'), icon: 'bar-chart-2', type: 'link', bookmark: true, active:true, autorizations: [
				// 'CODE_AUTHORIZATION_APP_INITIALIZATION'
				'GET_CAMPAIGN_REPORT'
			],
			childrenPath: [
			]
		},
		{
			id: 2, path: '/users/administrator', title: 'Administrator', icon: 'user', type: 'link', active:true, bookmark: true, autorizations: [
				'CREATE_USER','UPDATE_USER','DELETE_USER',
				'GET_USERS','MANAGE_USER_ROLE'
			],
			childrenPath: [
			]
		},
		{
			id: 3, path: '/users/saas-user', title: 'Saas User', icon: 'users', type: 'link', active:true, bookmark: true, autorizations: [
				'CREATE_SAAS_USER', 'UPDATE_SAAS_USER', 'DELETE_SAAS_USER', 'GET_SAAS_USERS', 'MANAGE_USER_ROLE'
			],
			childrenPath: [
			]
		},
		{
			id: 4, path: '/users/guest', title: 'Guest', icon: 'users', type: 'link', active:true, bookmark: true, autorizations: [
				'CREATE_GUEST_USER','UPDATE_GUEST_USER','DELETE_GUEST_USER',
				'GET_GUEST_USERS'
			],
			childrenPath: [
			]
		},
		{
			id: 5, path: '/users/role', title: 'Role Management', icon: 'shield', type: 'link', active:true, bookmark: true, autorizations: [
				'CODE_AUTHORIZATION_CREATE_ROLE','CODE_AUTHORIZATION_UPDATE_ROLE','CODE_AUTHORIZATION_DELETE_ROLE',
				'CODE_AUTHORIZATION_GET_ROLE'
			],
			childrenPath: [
				'/users/add-role', '/users/update-role'
			]
		},
		{
			id: 6, path: '/users/registration/list', title: 'Registrations', icon: 'list', type: 'link', active:true, bookmark: true, autorizations: [
				'UPDATE_SAAS_APPLICATION', 'DELETE_SAAS_APPLICATION', 'GET_SAAS_APPLICATIONS', 
				'CREATE_SAAS_USER_FROM_APPLICATION'
			],
			childrenPath: [
				'/users/registration/edit','/users/registration/add'
			]
		},
		{
			id: 7, path: '/contacts/list-contacts',title: 'Contacts Management', icon: 'share', type: 'link', active:true, bookmark: true, autorizations: [
				'IMPORTCSV_IMPORT','IMPORTCSV_GET','IMPORTCSV_DELETE'
			],
			childrenPath: [
				'/contacts/import-contacts'
			]
		},
		{
			id: 8, path: '/profile/list-profiles',title: 'Profiles Management', icon: 'user-check', type: 'link', active:true, bookmark: true, autorizations: [
				'PROFILE_CSV_SAVE','PROFILE_CSV_GET','PROFILE_DELETE','PROFILE_GET','PROFILE_CSV_CLAUSE_DELETE'
			],
			childrenPath: [
				'/profile/add-rest-profile', '/profile/add-csv-profile', '/profile/update-rest-profile', '/profile/update-csv-profile'
			]
		},
		{
			id: 9, path: '/operator/list-operators',title: 'Operators Management', icon: 'phone-call', type: 'link', active:true, bookmark: true, autorizations: [
				'OPERATOR_SAVE','OPERATOR_GET','OPERATOR_DELETE'
			],
			childrenPath: [
				'/operator/manage-sms-operator','/operator/manage-email-operator','/operator/update-sms-operator','/operator/update-email-operator'
			]
		},
		{
			id: 10, path: '/country/list-countries',title: 'Countries Management', icon: 'flag', type: 'link', active:true, bookmark: true, autorizations: [
				'COUNTRY_SAVE','COUNTRY_GET','COUNTRY_DELETE'
			],
			childrenPath: [
				'/country/add-country','/country/update-country'
			]
		},
		
		{
			id: 11, path: '/currency/list-currencies', title: 'Currencies Management', icon: 'dollar-sign', type: 'link', active:true, bookmark: true, autorizations: [
				'COUNTRY_SAVE','COUNTRY_GET','COUNTRY_DELETE'
			],
			childrenPath: [
				'/currency/add-currency','/currency/update-currency'
			]
		},
		
		{
			id: 12, path: '/campaign/list',title: 'Campaign Management', icon: 'rss', type: 'link', active:true, bookmark: true, autorizations: [
				'SAVE_CAMPAIGN', 'DELETE_CAMPAIGN', 'DELETE_CAMPAIGN_PROFILE', 'GET_CAMPAIGN', 'GET_CAMPAIGN_REPORT'
			],
			childrenPath: [
				'/campaign/add', '/campaign/edit' 
			]
		},
		{
			id: 13, path: '/billing/order/list',title: 'Billing and Payment', icon: 'credit-card', type: 'link', active:true, bookmark: true, autorizations: [
				'CREATE_BILL', 'DELETE_BILL', 'GET_BILL', 'PROCESSING_BILL', 'VALIDATE_BILL', 'REJECT_BILL',
				'CANCEL_BILL', 'GET_Balance'
			],
			childrenPath: [
				'/billing/order/new'
			]
		},
		{
			id: 14, path: '/tariff/list-tariffs',title: 'Tariff Management', icon: 'credit-card', type: 'link', active:true, bookmark: true, autorizations: [
				'SAVE_TARIFF', 'DELETE_TARIFF', 'GET_TARIFF'
			],
			childrenPath: [
				'/tariff/add-tariff', '/tariff/edit-tariff'
			]
		},
		{
			id: 15, path: '/settings/reload',title: 'Settings', icon: 'settings', type: 'link', active:true, bookmark: true, autorizations: [
				'CODE_AUTHORIZATION_APP_INITIALIZATION'
			],
			childrenPath: [
			]
		}
		
	]
	public MENUITEMS_FR: Menu[] = [
		{
			id: 1, path: '/dashboard', title: this.translate.instant('Tableau de bord'), icon: 'bar-chart-2', type: 'link', bookmark: true, active:true, autorizations: [
				// 'CODE_AUTHORIZATION_APP_INITIALIZATION'
				'GET_CAMPAIGN_REPORT'
			],
			childrenPath: [
			]
		},
		{
			id: 2, path: '/users/administrator', title: 'Administrateur', icon: 'user', type: 'link', active:true, bookmark: true, autorizations: [
				'CREATE_USER','UPDATE_USER','DELETE_USER',
				'GET_USERS','MANAGE_USER_ROLE'
			],
			childrenPath: [
			]
		},
		{
			id: 3, path: '/users/saas-user', title: 'Utilisateur Saas', icon: 'users', type: 'link', active:true, bookmark: true, autorizations: [
				'CREATE_SAAS_USER', 'UPDATE_SAAS_USER', 'DELETE_SAAS_USER', 'GET_SAAS_USERS', 'MANAGE_USER_ROLE'
			],
			childrenPath: [
			]
		},
		{
			id: 4, path: '/users/guest', title: 'Invité', icon: 'users', type: 'link', active:true, bookmark: true, autorizations: [
				'CREATE_GUEST_USER','UPDATE_GUEST_USER','DELETE_GUEST_USER',
				'GET_GUEST_USERS'
			],
			childrenPath: [
			]
		},
		{
			id: 5, path: '/users/role', title: 'Gestion des Roles', icon: 'shield', type: 'link', active:true, bookmark: true, autorizations: [
				'CODE_AUTHORIZATION_CREATE_ROLE','CODE_AUTHORIZATION_UPDATE_ROLE','CODE_AUTHORIZATION_DELETE_ROLE',
				'CODE_AUTHORIZATION_GET_ROLE'
			],
			childrenPath: [
				'/users/add-role', '/users/update-role'
			]
		},
		{
			id: 6, path: '/users/registration/list', title: 'Inscriptions', icon: 'list', type: 'link', active:true, bookmark: true, autorizations: [
				'UPDATE_SAAS_APPLICATION', 'DELETE_SAAS_APPLICATION', 'GET_SAAS_APPLICATIONS', 
				'CREATE_SAAS_USER_FROM_APPLICATION'
			],
			childrenPath: [
				'/users/registration/edit','/users/registration/add'
			]
		},
		{
			id: 7, path: '/contacts/list-contacts',title: 'Gestion des Contacts', icon: 'share', type: 'link', active:true, bookmark: true, autorizations: [
				'IMPORTCSV_IMPORT','IMPORTCSV_GET','IMPORTCSV_DELETE'
			],
			childrenPath: [
				'/contacts/import-contacts'
			]
		},
		{
			id: 8, path: '/profile/list-profiles',title: 'Gestion des Profiles', icon: 'user-check', type: 'link', active:true, bookmark: true, autorizations: [
				'PROFILE_CSV_SAVE','PROFILE_CSV_GET','PROFILE_DELETE','PROFILE_GET','PROFILE_CSV_CLAUSE_DELETE'
			],
			childrenPath: [
				'/profile/add-rest-profile', '/profile/add-csv-profile', '/profile/update-rest-profile', '/profile/update-csv-profile'
			]
		},
		{
			id: 9, path: '/operator/list-operators',title: 'Gestion des Operateurs', icon: 'phone-call', type: 'link', active:true, bookmark: true, autorizations: [
				'OPERATOR_SAVE','OPERATOR_GET','OPERATOR_DELETE'
			],
			childrenPath: [
				'/operator/manage-sms-operator','/operator/manage-email-operator','/operator/update-sms-operator','/operator/update-email-operator'
			]
		},
		{
			id: 10, path: '/country/list-countries',title: 'Gestion des pays', icon: 'flag', type: 'link', active:true, bookmark: true, autorizations: [
				'COUNTRY_SAVE','COUNTRY_GET','COUNTRY_DELETE'
			],
			childrenPath: [
				'/country/add-country','/country/update-country'
			]
		},
		
		{
			id: 11, path: '/currency/list-currencies', title: 'Gestions des dévises', icon: 'dollar-sign', type: 'link', active:true, bookmark: true, autorizations: [
				'COUNTRY_SAVE','COUNTRY_GET','COUNTRY_DELETE'
			],
			childrenPath: [
				'/currency/add-currency','/currency/update-currency'
			]
		},
		
		{
			id: 12, path: '/campaign/list',title: 'Gestion des Campagnes', icon: 'rss', type: 'link', active:true, bookmark: true, autorizations: [
				'SAVE_CAMPAIGN', 'DELETE_CAMPAIGN', 'DELETE_CAMPAIGN_PROFILE', 'GET_CAMPAIGN', 'GET_CAMPAIGN_REPORT'
			],
			childrenPath: [
				'/campaign/add', '/campaign/edit' 
			]
		},
		{
			id: 13, path: '/billing/order/list',title: 'Facturation et paiement', icon: 'credit-card', type: 'link', active:true, bookmark: true, autorizations: [
				'CREATE_BILL', 'DELETE_BILL', 'GET_BILL', 'PROCESSING_BILL', 'VALIDATE_BILL', 'REJECT_BILL',
				'CANCEL_BILL', 'GET_Balance'
			],
			childrenPath: [
				'/billing/order/new'
			]
		},
		{
			id: 14, path: '/tariff/list-tariffs',title: 'Gestion des Tarifs', icon: 'credit-card', type: 'link', active:true, bookmark: true, autorizations: [
				'SAVE_TARIFF', 'DELETE_TARIFF', 'GET_TARIFF'
			],
			childrenPath: [
				'/tariff/add-tariff', '/tariff/edit-tariff'
			]
		},
		{
			id: 15, path: '/settings/reload',title: 'Paramètres', icon: 'settings', type: 'link', active:true, bookmark: true, autorizations: [
				'CODE_AUTHORIZATION_APP_INITIALIZATION'
			],
			childrenPath: [
			]
		}
		
	]



	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	items2 = new BehaviorSubject<Menu[]>(this.MENUITEMS_FR);


}
