import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OperatorDTO, UserOperatorDTO } from 'src/app/components/contact/dto/operatorDTO';
import { RequestResult } from 'src/app/shared/model/RequestResult';
import { UserDto } from '../../dto/User.dto';
import { UserFormDto, UserOperatorInfoDTO } from '../../dto/UserForm.dto';
import { UserAdminRemoteService } from './user-admin-remote.service';

@Injectable({
  providedIn: 'root'
})
export class UserAdminService {

  //---------------------------------------------------------Admin API--------------------------------------------
  admin:UserDto[] =[];
  adminSubject:Subject<UserDto[]>=new Subject();
  totalPageSubject:Subject<number>=new Subject();
  totalPage:number=0;
  loadingSubject:Subject<boolean>=new Subject();
  isLoading:boolean;

  totalElementSubject: Subject<number> = new Subject();
  totalElement: number;

  emitTotalElement(){
    this.totalElementSubject.next(this.totalElement);
  }


  constructor(private userAdminRemoteService: UserAdminRemoteService) { }

  emitAdmin(){
    this.adminSubject.next(this.admin.slice());
 }
  emitPageNumber(){
    this.totalPageSubject.next(this.totalPage);
  }

  emitLoading(){
    this.loadingSubject.next(this.isLoading);
  }

  createAdmin(userFormDto: UserFormDto): Observable<RequestResult<any>> {
    return this.userAdminRemoteService.createAdmin(userFormDto);
  }

  getUserInfo(): Observable<RequestResult<UserDto>> {
    return this.userAdminRemoteService.getUserInfo();
  }

  activateUser(userCode: string): Observable<RequestResult<boolean>> {
    return this.userAdminRemoteService.activateUser(userCode);
  }

  desactivateUser(userCode: string): Observable<RequestResult<boolean>> {
    return this.userAdminRemoteService.desactivateUser(userCode);
  }

  getAllAdmin(search: string): Observable<RequestResult<UserDto[]>> {
    return this.userAdminRemoteService.getAllAdmin(search);
  }

  getAllAdminWithPagination(currentPage: number, pageSize: number,search:string){
    this.userAdminRemoteService.getAllAdminWithPagination(currentPage, pageSize,search).subscribe((result)=>{
      this.admin=result.data.content;
      this.totalPage=result.data.totalPages;
      this.emitPageNumber();
      this.emitAdmin();
      this.isLoading = false;
      this.emitLoading();
    });
 }


 addOperatorToSaasUser(userOperatorInfoDTO: UserOperatorInfoDTO): Observable<RequestResult<UserOperatorDTO>>{
  return this.userAdminRemoteService.addOperatorToSaasUser(userOperatorInfoDTO);
 }


 deleteAdmin(userCode: string): Observable<RequestResult<UserDto>> {
  return this.userAdminRemoteService.deleteAdmin(userCode);
}
deleteOperatorFromSaasUser(id: number ): Observable<RequestResult<boolean>> {
  return this.userAdminRemoteService.deleteOperatorFromSaasUser(id);
}

updateAdmin(userDto: UserDto): Observable<RequestResult<UserDto>> {
  return this.userAdminRemoteService.updateAdmin(userDto);
}




//-----------------------------------------------Saas User API----------------------------------------------
SaaSUser:UserDto[] =[];
SaaSUserSubject:Subject<UserDto[]>=new Subject();
totalPageSubjectSaaSUser:Subject<number>=new Subject();
totalPageSaaSUser:number=0;


emitSaaSUser(){
  this.SaaSUserSubject.next(this.SaaSUser.slice());
}
emitPageNumberSaaSUser(){
  this.totalPageSubjectSaaSUser.next(this.totalPageSaaSUser);
}

createSaaSUser(userFormDto: any): Observable<RequestResult<any>> {
  return this.userAdminRemoteService.createSaaSUser(userFormDto);
}

createSaaSUserApplication(userFormDto: any): Observable<RequestResult<any>> {
  return this.userAdminRemoteService.createSaaSUserApplication(userFormDto);
}

getAllSaaSUser(search: string): Observable<RequestResult<UserDto[]>> {
  return this.userAdminRemoteService.getAllSaaSUser(search);
}

getAllSaaSUserWithPagination(currentPage: number, pageSize: number,search:string){
  this.userAdminRemoteService.getAllSaaSUserWithPagination(currentPage, pageSize,search).subscribe((result)=>{
    this.SaaSUser=result.data.content;
    this.emitSaaSUser();
    this.totalPageSaaSUser=result.data.totalPages;
    this.totalElement = result.data.numberOfElements;
    this.emitTotalElement();
    this.emitPageNumberSaaSUser();
    this.isLoading = false;
    this.emitLoading();

  });
}
addSenderNameOfSaasUser(senderNameToAddObject: any): Observable<RequestResult<any>>{
  return this.userAdminRemoteService.addSenderNameOfSaasUser(senderNameToAddObject);
}

deleteSaaSUser(userCode: string): Observable<RequestResult<UserDto>> {
return this.userAdminRemoteService.deleteSaaSUser(userCode);
}

updateSaaSUser(userDto: UserDto): Observable<RequestResult<UserDto>> {
return this.userAdminRemoteService.updateSaaSUser(userDto);
}

getSaaSUserOnDB(userCode: string): Observable<RequestResult<UserDto>> {
  return this.userAdminRemoteService.getSaaSUserOnDB(userCode);
}

getSaasUserSenderNames(userCode: any): Observable<RequestResult<any>>{
  return this.userAdminRemoteService.getSaasUserSenderNames(userCode);
}

deleteSaasUserSenderName(id: number): Observable<RequestResult<any>>{
  return this.userAdminRemoteService.deleteSaasUserSenderName(id);
}

//-----------------------------------------------Guest API----------------------------------------------
guestUser:UserDto[] =[];
guestUserSubject:Subject<UserDto[]>=new Subject();
totalPageSubjectguestUser:Subject<number>=new Subject();
totalPageguestUser:number=0;

emitguestUser(){
  this.guestUserSubject.next(this.guestUser.slice());
}
emitPageNumberguestUser(){
  this.totalPageSubjectguestUser.next(this.totalPageguestUser);
}

createguestUser(userFormDto: UserFormDto): Observable<RequestResult<any>> {
  return this.userAdminRemoteService.createguestUser(userFormDto);
}

getAllguestUser(search: string): Observable<RequestResult<UserDto[]>> {
  return this.userAdminRemoteService.getAllguestUser(search);
}

getAllguestUserWithPagination(currentPage: number, pageSize: number,search:string){
  this.userAdminRemoteService.getAllguestUserWithPagination(currentPage, pageSize,search).subscribe((result)=>{
    this.guestUser=result.data.content;
    this.emitguestUser();
    this.totalPageguestUser=result.data.totalPages;
    this.totalElement = result.data.numberOfElements;
    this.emitTotalElement();
    this.emitPageNumberguestUser();
    
    this.isLoading = false;
    this.emitLoading();
  });
}

deleteguestUser(userCode: string): Observable<RequestResult<UserDto>> {
return this.userAdminRemoteService.deleteguestUser(userCode);
}

updateguestUser(userDto: UserDto): Observable<RequestResult<UserDto>> {
return this.userAdminRemoteService.updateguestUser(userDto);
}

getguestOfSaaSUser(saasUserCode: string): Observable<RequestResult<UserDto>> {
  return this.userAdminRemoteService.getguestOfSaaSUser(saasUserCode);
}

getguestUserOnDB(userCode: string): Observable<RequestResult<UserDto>> {
  return this.userAdminRemoteService.getguestUserOnDB(userCode);
}




}
