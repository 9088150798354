<div class="page-wrapper">
    <div class="comingsoon comingsoon-bgimg">
        <!-- <div class="auth-bg"> -->
        <div class="language-selection">
            <ul class="nav-menus">
                <li class="onhover-dropdown">
                    <h5 class="txt-dark">{{'Lang' | translate}}</h5>
                    <ul class="language-dropdown ">
                        <li><a href="javascript:void(0)" data-lng="en" (click)="changeLanguage('en')"><i
							class="flag-icon flag-icon-is"></i> {{'EN' | translate}}</a></li>
                        <li><a href="javascript:void(0)" data-lng="fr" (click)="changeLanguage('fr')"><i
							class="flag-icon flag-icon-fr"></i> {{'FR' | translate}}</a></li>
                    </ul>
                </li>
            </ul>
        </div>

        <div class="authentication-box">
            <div class="text-center">
                <img style="width:auto;margin-left: 50px;height: 4rem;margin-top: 2rem;" src="{{baseUrl+logoImage}}" alt="{{appInfo?appInfo.logoName:''}}">
                <!-- <img src="assets/images/endless-logo.png" alt=""> -->
                <!-- <span class="title">Smart Digital Marketing</span> -->
                <!-- <span class="title">Digital Marketing</span> -->
            </div>

            <div class="card mt-4">
                <div class="card-header b-t-secondary">
                    <div class="text-center">
                        <h1>{{'Login' | translate}}</h1>

                    </div>
                </div>
                <div class="card-body">
                    <form class="theme-form" [formGroup]="loginForm">
                        <div class="form-group">
                            <label class="col-form-label pt-0">{{'Email Address' | translate}}</label>
                            <div class="input-group pill-input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input class="form-control" formControlName="email" type="email" required="" title="{{'Email Address' | translate}}">
                            </div>
                            <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required" class="text text-danger mt-1">
                                {{'This field is required' |translate}}
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-form-label">{{'Password' | translate}}</label>
                            <div class="input-group pill-input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-lock"></i></span>
                                </div>
                                <input class="form-control" type="password" formControlName="password" required placeholder="***************">
                            </div>

                            <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required" class="text text-danger mt-1">
                                {{'This field is required' |translate}}
                            </div>
                        </div>
                        <div class="checkbox p-0">
                            <input id="checkbox1" type="checkbox" [value]="isChecked" (change)="onChange($event.target.checked)">
                            <label for="checkbox1">{{'Remember me' | translate}}</label>
                            <div class="text-center">
                                <a [routerLink]="'/auth/forgetpassword'">{{'Forgot password' | translate}}?</a>
                            </div>
                        </div>

                        <div class="form-group form-row mt-3 mb-0">
                            <button style="background-color:#123240" class="btn btn-primary btn-block btn-lg" [disabled]="!loginForm.valid || showLoader" (click)="login()" type="submit"><span>{{ showLoader ? 'Loading...' : 'Login' | translate }}</span>
							</button>
                        </div>
                        <div class="font-16 weight-600 pt-10 mt-2 pb-10 text-center" data-color="#707373">--{{'OR' | translate}}--</div>
                        <div class="input-group mt-2">
                            <button class="btn btn-outline-primary btn-lg btn-block" [disabled]="showLoader" [routerLink]="'/auth/register'">{{'Register To Create Account' | translate}}</button>
                        </div>
                        <!-- <div class="login-divider"></div>
						<div class="social mt-3">
							<div class="form-group btn-showcase d-flex">
								<button class="btn social-btn btn-fb d-inline-block" (click)="loginFacebook()">
									<i class="fa fa-facebook"></i>
								</button>
								<button class="btn social-btn btn-twitter d-inline-block" (click)="loginTwitter()">
									<i class="fa fa-twitter"></i>
								</button>
								<button class="btn social-btn btn-google d-inline-block" (click)="loginGoogle()">
									<i class="fa fa-google"></i>
								</button>
							</div>
						</div> -->
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>